import { saveAs } from 'file-saver';
import { makeAutoObservable, runInAction } from 'mobx';
import {
  CATEGORIZE_QUESTIONS,
  EXPLAIN_FORMAT,
  FACT_CHECK,
  GENERATE_QUESTIONS_FROM_PAST_QUIZZES,
  GENERATE_SIMILAR_QUESTIONS,
} from '../prompts/prompts';
import { quickToast } from '../utils/toast';
import { callAi, Message } from './aiCall';
import { defaultQuestions, defaultQuizzes } from './defaults';

// TODO: clean up this interface because quizStore is a megaclass

type QuestionObjParams = {
  id: number;
  question: string;
  answer: string;
  category?: string;
  approved?: boolean;
  number?: number;
  quizName?: string;
};

export class QuestionObj {
  id: number;
  question: string;
  answer: string;
  category?: string;
  approved: boolean;
  // what number question was it (e.g 1 is the first question)
  number?: number;
  quizName?: string;

  constructor({
    id,
    question,
    answer,
    category,
    approved = false,
    number,
    quizName,
  }: QuestionObjParams) {
    this.id = id;
    this.question = question;
    this.answer = answer;
    this.category = category;
    this.approved = approved;
    this.number = number;
    this.quizName = quizName;
  }
}

export interface QuizObj {
  // quiz questions are stored in the questions array
  name: string;
  description: string;
  // TODO: think of a better name for this
  // we use these to generate new quizzes from
  favourite: boolean;
  lastUpdated: Date;
}

async function generateQuestionsHelper(messages: Message[]) {
  const response = await callAi(messages);
  const json = response.substring(
    response.indexOf('['),
    response.lastIndexOf(']') + 1,
  );
  try {
    return JSON.parse(json);
  } catch (e) {
    console.error('Error parsing JSON', e);
    console.error('Response:', response);
    throw e;
  }
}

function generateFromPastQuizzesPrompt(
  quizzes: string[],
  questions: QuestionObj[],
) {
  // Print all quizzes sequentially with each quiz including all questions in numbered order
  let allQuestionsString = '';
  quizzes.forEach((quizName) => {
    const quizQuestions = questions.filter((q) => q.quizName === quizName);
    // sort in question order
    quizQuestions.sort((a, b) => (a.number ?? 0) - (b.number ?? 0));
    allQuestionsString += `## ${quizName}\n`;
    quizQuestions.forEach((q) => {
      allQuestionsString += `${q.number}. ${q.question} (answer: ${q.answer})\n`;
    });
  });
  return GENERATE_QUESTIONS_FROM_PAST_QUIZZES + allQuestionsString;
}

class QuizStore {
  questions: QuestionObj[] = [];
  quizzes: QuizObj[] = [];
  currentQuiz: string;
  categories: string[] = [];
  nextId = 1;

  constructor() {
    makeAutoObservable(this);
    this.currentQuiz = this.quizzes[0]?.name;
    if (!this.currentQuiz) {
      this.quizzes.push({
        name: 'Current',
        description: '',
        favourite: false,
        lastUpdated: new Date(),
      });
      this.currentQuiz = 'Current';
    }
    this.loadState();
  }

  get scratchpadQuestions() {
    return this.questions.slice().reverse();
  }

  get approvedQuestions() {
    return this.questions
      .filter((q) => q.approved && !q.quizName)
      .slice()
      .reverse();
  }

  get currentQuizQuestions() {
    return this.questions
      .filter((q) => q.quizName === this.currentQuiz)
      .sort((a, b) => (a.number ?? 0) - (b.number ?? 0));
  }

  get pastQuizQuestions() {
    return this.questions
      .filter((q) => q.quizName && q.quizName !== this.currentQuiz)
      .slice()
      .reverse();
  }

  get favouriteQuizzes() {
    return this.quizzes.filter((q) => q.favourite).map((q) => q.name);
  }

  createNewQuiz(quizName: string) {
    // name must be unique
    if (this.quizzes.some((q) => q.name === quizName)) {
      console.log('Quiz name must be unique');
      quickToast('Quiz name must be unique');
      return;
    }
    const newQuiz = {
      name: quizName,
      description: '',
      favourite: false,
      lastUpdated: new Date(),
    };
    this.quizzes = [newQuiz, ...this.quizzes];
    this.setCurrentQuiz(quizName);
    return newQuiz;
  }

  renameQuiz(oldName: string, newName: string) {
    if (this.quizzes.some((q) => q.name === newName)) {
      quickToast('Quiz name must be unique');
      return;
    }
    runInAction(() => {
      const quiz = this.quizzes.find((q) => q.name === oldName);
      if (quiz) {
        quiz.name = newName;
        // update all questions with the new quiz name
        this.questions.forEach((q) => {
          if (q.quizName === oldName) {
            q.quizName = newName;
          }
        });
        if (this.currentQuiz === oldName) {
          this.currentQuiz = newName;
        }
        this.saveState();
      }
    });
  }

  reorderQuiz(quizName: string, newPosition: number) {
    runInAction(() => {
      const quiz = this.quizzes.find((q) => q.name === quizName);
      if (quiz) {
        this.quizzes = this.quizzes.filter((q) => q.name !== quizName);
        this.quizzes.splice(newPosition, 0, quiz);
        this.saveState();
      }
    });
  }

  updateQuizDescription(quizName: string, description: string) {
    runInAction(() => {
      const quiz = this.quizzes.find((q) => q.name === quizName);
      if (quiz) {
        quiz.description = description;
        this.saveState();
      }
    });
  }

  setCurrentQuiz(quizName: string) {
    let quiz = this.quizzes.find((q) => q.name === quizName);
    if (quiz) {
      this.currentQuiz = quiz.name;
      this.saveState();
      return;
    }
    this.createNewQuiz(quizName);
    this.saveState();
    return;
  }

  deleteQuiz(quizName: string) {
    this.quizzes = this.quizzes.filter((q) => q.name !== quizName);
    // keep questions but remove quizName
    this.questions.forEach((q) => {
      if (q.quizName === quizName) {
        q.quizName = undefined;
      }
    });
    if (this.quizzes.length === 0) {
      const newQuiz = {
        name: 'Current',
        description: '',
        favourite: false,
        lastUpdated: new Date(),
      };
      this.currentQuiz = newQuiz.name;
      this.quizzes.push(newQuiz);
    } else {
      this.currentQuiz = this.quizzes[0].name;
    }
    this.saveState();
  }

  toggleFavourite(quizName: string) {
    let isFavourite = false;
    runInAction(() => {
      const MAX_FAVOURITE_COUNT = 3;
      let quiz = this.quizzes.find((q) => q.name === quizName);
      console.log('toggleFavourite start', { fav: quiz?.favourite, quizName });
      if (!quiz) {
        console.error('Quiz not found');
        return;
      }
      if (!quiz.favourite) {
        if (
          this.quizzes.filter((q) => q.favourite).length < MAX_FAVOURITE_COUNT
        ) {
          quiz.favourite = true;
        } else {
          quickToast(`You can only favourite ${MAX_FAVOURITE_COUNT} quizzes`);
        }
      } else {
        quiz.favourite = false;
      }
      console.log('toggleFavourite end', {
        fav: quiz?.favourite,
        quizName,
      });
      isFavourite = quiz.favourite;
      this.saveState();
    });
    return isFavourite;
  }

  async generateFromPrompt(prompt: String) {
    const messages: Message[] = [
      {
        role: 'system',
        content: generateFromPastQuizzesPrompt(
          this.favouriteQuizzes,
          this.questions,
        ),
      },
      { role: 'user', content: prompt.toString() },
    ];
    const questions = await generateQuestionsHelper(messages);
    runInAction(() => {
      questions.forEach((q: QuestionObj) =>
        this.addQuestion(q.question, q.answer),
      );
    });
  }

  async generateQuestions() {
    const messages: Message[] = [
      {
        role: 'system',
        content: generateFromPastQuizzesPrompt(
          this.favouriteQuizzes,
          this.questions,
        ),
      },
    ];
    const questions = await generateQuestionsHelper(messages);
    runInAction(() => {
      questions.forEach((q: QuestionObj) =>
        this.addQuestion(q.question, q.answer),
      );
    });
  }

  async generateFromPastQuizzes(quizzes: string[]) {
    // Print all quizzes sequentially with each quiz including all questions in numbered order
    let allQuestionsString = '';
    quizzes.forEach((quizName) => {
      const quizQuestions = this.questions.filter(
        (q) => q.quizName === quizName,
      );
      // sort in question order
      quizQuestions.sort((a, b) => (a.number ?? 0) - (b.number ?? 0));
      allQuestionsString += `## ${quizName}\n`;
      quizQuestions.forEach((q) => {
        allQuestionsString += `${q.number}. ${q.question} (answer: ${q.answer})\n`;
      });
    });
    const messages: Message[] = [
      {
        role: 'system',
        content: GENERATE_QUESTIONS_FROM_PAST_QUIZZES + allQuestionsString,
      },
    ];
    const questions = await generateQuestionsHelper(messages);
    runInAction(() => {
      questions.forEach((q: QuestionObj) =>
        this.addQuestion(q.question, q.answer),
      );
    });
  }

  addCategory(category: string) {
    if (this.categories.includes(category)) {
      alert('Category already exists');
      return;
    }
    this.categories.push(category);
    this.saveState();
  }

  removeCategory(category: string) {
    this.categories = this.categories.filter((c) => c !== category);
    this.saveState();
  }

  async categorizeQuestions() {
    if (this.categories.length === 0) {
      alert('No categories found. Please add categories first.');
      return;
    }
    const messages: Message[] = [
      { role: 'system', content: CATEGORIZE_QUESTIONS },
      { role: 'user', content: '## Categories\n' + this.categories.join('\n') },
      {
        role: 'user',
        content: JSON.stringify(
          this.currentQuizQuestions.map((q) => {
            return { question: q.question, answer: q.answer };
          }),
        ),
      },
    ];
    console.log({ messages });
    const questions = await generateQuestionsHelper(messages);
    const currentQuizQuestions = this.currentQuizQuestions;
    console.log({
      questions: questions.map((q: QuestionObj) => JSON.stringify(q)),
      currentQuizQuestions,
    });
    runInAction(() => {
      questions.forEach((q: QuestionObj, i: number) => {
        currentQuizQuestions[i].category = q.category;
      });
    });
  }

  getMissingCategoriesInCurrentQuiz() {
    // if there are less than 2 question in the category, add it to the list
    const categories = this.currentQuizQuestions.reduce(
      (acc: Record<string, number>, q) => {
        if (q.category) {
          acc[q.category] = acc[q.category] ? acc[q.category] + 1 : 1;
        }
        return acc;
      },
      {},
    );
    return this.categories.filter((c) => categories[c] ?? 0 < 2);
  }

  async generateQuestionsForCategory(category: string) {
    const messages: Message[] = [
      {
        role: 'system',
        content: generateFromPastQuizzesPrompt(
          this.favouriteQuizzes,
          this.questions,
        ),
      },
      { role: 'user', content: `## Category\n${category}` },
    ];
    const questions = await generateQuestionsHelper(messages);
    runInAction(() => {
      questions.forEach((q: QuestionObj) =>
        this.addQuestion(q.question, q.answer),
      );
      this.saveState();
    });
  }

  async generateFromFilteredQuestions(inputQuestions: QuestionObj[]) {
    const randomOrder = inputQuestions.sort(() => Math.random() - 0.5);
    const questionsString = randomOrder
      .slice(-8)
      .map((q) => q.question)
      .join('\n');
    const messages: Message[] = [
      { role: 'system', content: EXPLAIN_FORMAT },
      {
        role: 'user',
        content:
          'Generate 10 new trivia questions similar to the following:\n' +
          questionsString,
      },
    ];
    const questions = await generateQuestionsHelper(messages);
    runInAction(() => {
      questions.forEach((q: QuestionObj) =>
        this.addQuestion(q.question, q.answer),
      );
      this.saveState();
    });
  }

  async generateSimilarQuestions(question: string) {
    const messages: Message[] = [
      { role: 'system', content: GENERATE_SIMILAR_QUESTIONS },
      {
        role: 'user',
        content: question,
      },
    ];
    const questions: QuestionObj[] = await generateQuestionsHelper(messages);
    runInAction(() => {
      questions.forEach((q) => this.addQuestion(q.question, q.answer));
    });
  }

  async factCheck(question: QuestionObj) {
    const messages: Message[] = [
      {
        role: 'system',
        content: FACT_CHECK,
      },
      {
        role: 'user',
        content: `Q) ${question.question}\nA) ${question.answer}`,
      },
    ];
    const response = await callAi(messages);
    return response;
  }

  async askQuestion(question: QuestionObj, chatAreaQuestion: string) {
    const messages: Message[] = [
      {
        role: 'system',
        content: chatAreaQuestion,
      },
      {
        role: 'user',
        content: `Q) ${question.question}\nA) ${question.answer}`,
      },
    ];
    const response = await callAi(messages);
    return response;
  }

  updateQuestion(id: number, updatedQuestion: string, updatedAnswer: string) {
    const question = this.questions.find((q) => q.id === id);
    if (question) {
      runInAction(() => {
        question.question = updatedQuestion;
        question.answer = updatedAnswer;
        this.saveState();
      });
    }
  }

  addQuestion(question: string, answer: string): QuestionObj {
    const questionObj = new QuestionObj({
      id: this.nextId++,
      question,
      answer,
      approved: false,
    });
    runInAction(() => {
      this.questions.push(questionObj);
      this.saveState();
      // if over 1000 questions unapproved or not in a quiz, bring it down to 800
      const scratchpadQuestions = this.questions.filter(
        (q) => !q.approved && !q.quizName,
      );
      if (scratchpadQuestions.length > 1000) {
        const toRemove = scratchpadQuestions.slice(800);
        this.removeQuestions(new Set(toRemove.map((q) => q.id)));
      }
    });
    return questionObj;
  }

  removeQuestion(id: number) {
    runInAction(() => {
      this.questions = this.questions.filter((q) => q.id !== id);
      this.saveState();
    });
  }

  removeQuestions(ids: Set<number>) {
    runInAction(() => {
      this.questions = this.questions.filter((q) => !ids.has(q.id));
      this.saveState();
    });
  }

  approveQuestion(id: number) {
    const question = this.questions.find((q) => q.id === id);
    if (question) {
      runInAction(() => {
        question.approved = true;
        this.saveState();
      });
    }
  }

  unapproveQuestion(id: number) {
    const question = this.questions.find((q) => q.id === id);
    if (question) {
      runInAction(() => {
        question.approved = false;
        this.saveState();
      });
    }
  }

  changeNumber(id: number, newNumber: number) {
    const question = this.questions.find((q) => q.id === id);
    if (question) {
      runInAction(() => {
        question.number = newNumber;
        this.saveState();
      });
    }
  }

  addQuestionToQuiz(id: number) {
    const currentQuizQuestions = this.currentQuizQuestions;
    if (currentQuizQuestions.some((q) => q.id === id)) {
      quickToast('Question already in quiz');
      return;
    }
    const questionIndex = this.questions.findIndex((q) => q.id === id);
    if (questionIndex !== -1) {
      runInAction(() => {
        const updatedQuestion = {
          ...this.questions[questionIndex],
          quizName: this.currentQuiz,
          approved: true,
          number:
            Math.max(...currentQuizQuestions.map((q) => q.number ?? 0), 0) + 1,
        };
        this.questions[questionIndex] = updatedQuestion;

        const currentQuizIndex = this.quizzes.findIndex(
          (q) => q.name === this.currentQuiz,
        );
        if (currentQuizIndex === -1) {
          throw new Error('Current quiz not found');
        }
        this.quizzes[currentQuizIndex] = {
          ...this.quizzes[currentQuizIndex],
          lastUpdated: new Date(),
        };
        this.saveState();
      });
    } else {
      console.error('Question not found', id);
    }
  }

  removeQuestionFromQuiz(id: number) {
    const question = this.questions.find((q) => q.id === id);
    if (question) {
      runInAction(() => {
        question.quizName = undefined;
        // update all numbers higher than this to be 1 number lower
        const currentQuizQuestions = this.currentQuizQuestions;
        if (question.number) {
          currentQuizQuestions.forEach((q) => {
            if (q.number && q.number > question.number!) {
              q.number -= 1;
            }
          });
        }
        question.number = undefined;
        this.saveState();
      });
    }
  }

  saveState() {
    localStorage.setItem('questions', JSON.stringify(this.questions));
    localStorage.setItem('quizzes', JSON.stringify(this.quizzes));
    localStorage.setItem('currentQuiz', JSON.stringify(this.currentQuiz));
    localStorage.setItem('categories', JSON.stringify(this.categories));
    localStorage.setItem('nextId', JSON.stringify(this.nextId));
  }

  loadState() {
    // TODO: add a function to check if the state is valid
    /*
    - If questions mention a quiz make sure that quiz exists
    - Check questions only reference saved categories
    - Make sure nextId is greater than all question ids
    */
    const savedQuestions = localStorage.getItem('questions');
    const savedQuizzes = localStorage.getItem('quizzes');
    const savedCurrentQuiz = localStorage.getItem('currentQuiz');
    const savedCategories = localStorage.getItem('categories');
    const savedNextId = localStorage.getItem('nextId');

    if (savedQuestions) {
      this.questions = JSON.parse(savedQuestions);
    } else {
      this.questions = defaultQuestions;
    }
    if (savedQuizzes) {
      this.quizzes = JSON.parse(savedQuizzes);
    } else {
      this.quizzes = defaultQuizzes;
    }
    if (savedCurrentQuiz) {
      this.currentQuiz = JSON.parse(savedCurrentQuiz);
    } else {
      this.currentQuiz = this.quizzes[0].name;
    }
    if (savedCategories) {
      this.categories = JSON.parse(savedCategories);
    } else {
      this.categories = [
        'Food and Beverages',
        'Music and Songs',
        'Literature and Authors',
        'Movies and Directors',
        'Companies and Brands',
        'Sports',
        'Science and Technology',
        'Geography and Places',
        'History and Events',
      ];
    }
    if (savedNextId) {
      this.nextId = JSON.parse(savedNextId);
    } else {
      this.questions.forEach((q) => {
        this.nextId = Math.max(this.nextId, q.id + 1);
      });
    }
  }

  exportToTSV(quizName: string) {
    const header = 'quiz name\tquestion number\tquestion\tanswer\n';
    const questions = this.questions
      .filter((q) => q.quizName === quizName)
      .sort((a, b) => (a.number ?? 0) - (b.number ?? 0));
    const rows = questions
      .map(
        (q, index) => `${q.quizName}\t${index + 1}\t${q.question}\t${q.answer}`,
      )
      .join('\n');
    const tsvContent = header + rows;
    const blob = new Blob([tsvContent], { type: 'text/tsv;charset=utf-8;' });
    saveAs(blob, 'quiz.tsv');
  }

  removeDuplicateQuestions() {
    const questionSet = new Set<string>();
    const newQuestions = this.questions.filter((q) => {
      if (questionSet.has(q.quizName + q.question + q.answer)) {
        return false;
      }
      questionSet.add(q.quizName + q.question + q.answer);
      return true;
    });
    this.questions = newQuestions;
    this.saveState();
  }

  async importFromTSV(file: File | undefined) {
    if (file) {
      const text = await file.text();
      const lines = text.split('\n');
      const questions: QuestionObj[] = [];
      let newQuizzes = new Set<string>();
      for (let i = 0; i < lines.length; i++) {
        const [quizName, number, question, answer] = lines[i].split('\t');
        if (this.quizzes.every((q) => q.name !== quizName)) {
          newQuizzes.add(quizName);
        }
        // if question already exists, and is in the same quiz, skip
        if (
          this.questions.some(
            (q) =>
              q.quizName === quizName &&
              q.question === question &&
              q.answer === answer,
          )
        ) {
          continue;
        }
        questions.push(
          new QuestionObj({
            number: parseInt(number),
            question,
            answer,
            id: this.nextId++,
            quizName: quizName,
            approved: true,
          }),
        );
      }
      runInAction(() => {
        this.questions = this.questions.concat(questions);
        this.quizzes = this.quizzes.concat(
          Array.from(newQuizzes).map((quiz) => ({
            name: quiz,
            description: '',
            favourite: false,
            lastUpdated: new Date(),
          })),
        );
        this.saveState();
      });
    }
  }
}

const quizStore = new QuizStore();
export default quizStore;
